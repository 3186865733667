.moduloListas{
    font-family: Arial, Helvetica, sans-serif;
    padding-left: 60px;
    padding-right: 60px;
    background-color: white;
    border: 1px solid white;
    border-radius: 4px;
    min-height: 100%;
}

.moduloListas .titulo{
    margin-top: 60px;
    font-size: 30px;
    margin-bottom: 5px;
    margin-left: 15px;
}

.contenedorAcciones{
    margin-top: 45px;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    column-gap: 40px;
}

.contenedorAcciones .txtBuscadorProducto{
    width: 100%;
    height: 38px;
    margin-right: 20px;
    border: none;
    background-color: rgb(235, 235, 235);
    padding-left: 15px;
    border-radius: 4px;

}

.contenedorAcciones .txtBuscadorProducto:focus{
    border:2px solid #2684ff;
    outline: none;
}

.btnDescargarLista{
    height: 37px;
    width: 100%;
    border: none;
    background-color: #ffb300;
    cursor: pointer;
    border-radius: 4px;
}

.hr1{
    margin-top: 45px;
    margin-bottom: 40px;
}

.contenedorProductos .tablaProductos{
    width: 100%;
}

.contenedorProductos .tablaProductos thead tr td{
    color: #000d58;
    font-weight: 560;
    font-size: 15px;
    text-align: center;
    width: 33.3%;
    background-color: rgb(235, 235, 235);
    padding-top: 12px;
    padding-bottom: 12px;
    user-select: none;
}

.contenedorProductos .tablaProductos thead tr td:nth-child(4){
    padding-right: 15px;
}

.contenedorProductos .tablaProductos tbody tr td{
    height: 35px;
    text-align: center;
    border-bottom: 2.5px solid rgb(235, 235, 235);
}



.filaProducto td{
    padding:12px 0;
    font-size: 13.5px;
    /* font-weight: 550; */
    /* color: #424242; */
}

.filaProducto:hover{
    background-color: rgb(235, 235, 235);
    cursor: pointer;
}


.css-1s2u09g-control{
    background-color: rgb(235, 235, 235)!important;
    border: none !important;
}

.css-1pahdxg-control{
    background-color: rgb(235, 235, 235) !important;
}