.formularioLogin{
    background-color: white;
    width: 370px;
    height: 500px;
    padding: 60px 35px;
    border-radius: 5px;
    box-shadow: 8px 8px rgb(4, 0, 37);
    font-family: Arial, Helvetica, sans-serif;
}

.formularioLogin .txtBienvenido{
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 30px;
    font-weight: bold;
}

.formularioLogin .inputFormularioLogin{
    width: 100%;
    height: 37px;
    margin-top: 30px;
    border: none;
    background-color: rgb(216, 216, 216);
    padding-left: 10px;
}

.formularioLogin .hr1{
    margin-top: 10px;
    margin-bottom: 15px;
}

.formularioLogin .gridAccionesPassword{
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    margin-top: 15px;
}

.formularioLogin .txtRecuperarPassword{
    text-align: right;
    font-size: 14px;
}

.formularioLogin label{
    font-size: 14px;
}

.formularioLogin .btnIniciarSesion{
    width: 100%;
    margin-top: 70px;
    height: 37px;
    border: none;
    background-color: #ffb300;
    border-radius: 3px;
    font-weight:500;
    cursor: pointer;
}

.formularioLogin .hr2{
    margin-top: 30px;
}