.paginador{
    margin-top: 40px;
    text-align: center;
    color: #000d58;
    margin-bottom: 30px;
}

.paginador ul{
    margin-bottom: 40px;
    display: inline-block;
}
.paginador li{
    list-style: none;
    display: inline-block;
    padding: 5px;
    border: 1px solid rgb(235, 235, 235);
    min-width: 30px;
    height: 30px;
    margin: 3px;
    cursor: pointer;
    border-radius: 3px;
}

.paginador .seleccionada{
    background-color: #000d58;
    color: white;
}
.paginador p{
    display: inline-block;
}

.paginador .btnInicioPaginador{
    margin-right: 10px;
}

.paginador .btnFinPaginador{
    margin-left: 10px;
}

.paginador .btnInicioPaginador, .paginador .btnFinPaginador{
    cursor: pointer;
}