.login{
    width: 100%;
    height: 100%;
    background-color: #000d58;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login .contImgLogo{
    position: absolute;
    top: 40px;
    left: 50px;
}

.login .contImgLogo img{
    width: 140px;
}