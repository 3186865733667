/*VENTANA DESCARGAR LISTAS*/
.modalDescargarLista{
    margin-top: 40px;
    min-height: 130px;
    border-radius: 4px;
    padding: 30px 30px;
    box-shadow: 3px 3px 6px rgb(189, 189, 189);
    border: 1px solid rgb(238, 238, 238);
    /* background-color: rgb(248, 248, 248); */
    background-color: rgb(252, 252, 255);
}

.modalDescargarLista .contAccionesDescLista{
    display: grid;
    grid-template-columns: 2fr repeat(2,1.5fr);
    margin-top: 20px;
    column-gap: 30px;
}

.contAccionesDescLista .contTituloDescListas{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.contAccionesDescLista .contTituloDescListas p{
    font-size: 15px;
}

.contAccionesDescLista .contSelectDescListas select{
    width: 100%;
    height: 35px;
    background-color: rgb(235, 235, 235);
    border: none;
    padding-left: 10px;
    border-radius: 4px;
}

.contAccionesDescLista .contBtnDescargarLista{
   text-align: right;
}

.contAccionesDescLista .contBtnDescargarLista button{
    width: 100%;
    height: 35px;
    /* background-color: rgb(235, 235, 235); */
    background-color: #000d58;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.txtDescargarLista{
    font-size: 20px;
}
/*FIN DE VENTANA DESCARGAR LISTAS*/